import React from "react";
import { Link } from "gatsby";

import "./homeNav.scss";

const HomeNav = () => (
	<div className="home-nav">
		<Link to="/">Home</Link>
	</div>
);

export default HomeNav;
