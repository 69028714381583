import React from "react";

import "./post.scss";

const Post = ({ title, html }) => (
	<>
		<h1 className="post-title">
			{title}
		</h1>
		<div
			className="post-content"
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	</>
);

export default Post;
